/* eslint-disable max-len */
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const AppleFaceId: React.FC<{ size: number; color: string }> = ({ size = 20, color }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 64 64">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 17.563v-7.188a7.133 7.133 0 017.125-7.125h7.188a1.625 1.625 0 000-3.25h-7.188C4.655 0 0 4.654 0 10.375v7.188a1.625 1.625 0 003.25 0zM17.563 60.75a1.625 1.625 0 010 3.25h-7.188C4.655 64 0 59.346 0 53.625v-7.188a1.625 1.625 0 013.25 0v7.188a7.133 7.133 0 007.125 7.125h7.188zM64 46.437v7.188C64 59.345 59.346 64 53.625 64h-7.188a1.625 1.625 0 010-3.25h7.188a7.133 7.133 0 007.125-7.125v-7.188a1.625 1.625 0 013.25 0zm0-36.062v7.188a1.625 1.625 0 01-3.25 0v-7.188a7.133 7.133 0 00-7.125-7.125h-7.188a1.625 1.625 0 010-3.25h7.188C59.345 0 64 4.654 64 10.375zM43.214 47.302a1.687 1.687 0 00-2.29-2.48A13.115 13.115 0 0132 48.313c-3.319 0-6.488-1.239-8.924-3.49a1.688 1.688 0 00-2.29 2.48A16.481 16.481 0 0032 51.687c4.17 0 8.153-1.557 11.214-4.385zm-7.839-23.177v12a4.317 4.317 0 01-4.313 4.313h-1.375a1.687 1.687 0 110-3.376h1.375c.517 0 .938-.42.938-.937v-12a1.687 1.687 0 113.375 0zM47 28.844V24.03a1.594 1.594 0 00-3.188 0v4.813a1.594 1.594 0 003.188 0zm-29.625 0a1.594 1.594 0 003.188 0V24.03a1.594 1.594 0 00-3.188 0v4.813z"
      fill={color}
      stroke={color}
      strokeWidth={3}
    />
  </Svg>
);

export default AppleFaceId;
