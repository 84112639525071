import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AnyAction, combineReducers, configureStore, AsyncThunkAction } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

import { appState, auth, firebase, notifications, ui, user, stt } from './reducers';
import {
  focusToolbarInputOnMessageAppend,
  scrollChatToBottomOnMessageAppend,
  getProfileOnTokenUpdate,
  refreshFCMToken,
  onBackgroundNotificationOpened,
  onReceiveNotification
} from './epics';
import { isWeb } from '@common/utils';
import { AuthState } from '@common/store/reducers/auth';
import { MigrationManifest, PersistedState } from 'redux-persist/es/types';

const persistConfig = {
  key: 'common',
  version: 1,
  storage: AsyncStorage,
  blacklist: ['auth', 'stt']
};

type PersistedAuthState = AuthState & {
  _persist: {
    version: number;
    rehydrated: boolean;
  };
};

const migrations: MigrationManifest = {
  1: (state: PersistedState): PersistedAuthState => {
    if (!state) {
      return {
        savedEmail: '',
        biometrics: false,
        isLoading: false,
        isOAuthLoading: false,
        redirectUrl: '',
        token: '',
        _persist: {
          version: 1,
          rehydrated: true
        }
      };
    }

    const existingState = state as unknown as PersistedAuthState;
    const { _persist } = existingState;

    return {
      ...existingState,
      savedEmail: '',
      biometrics: false,
      _persist
    };
  }
};

const authPersistConfig = {
  key: 'auth',
  storage: AsyncStorage,
  version: 2,
  autoMergeLevel2,
  migrate: createMigrate(migrations),
  blacklist: ['isOAuthLoading']
};

const rootReducer = combineReducers({
  appState,
  auth: persistReducer(authPersistConfig, auth),
  firebase,
  notifications,
  ui,
  user,
  stt
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).prepend(epicMiddleware),
  devTools: true
});

const platformEpics = isWeb ? [focusToolbarInputOnMessageAppend] : [onBackgroundNotificationOpened, refreshFCMToken];

const epics = combineEpics(
  focusToolbarInputOnMessageAppend,
  scrollChatToBottomOnMessageAppend,
  getProfileOnTokenUpdate,
  onReceiveNotification,
  ...platformEpics
);
epicMiddleware.run(epics);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const commonContext = createContext({} as ReactReduxContextValue<any, AnyAction>);

export type ThunkAction = AsyncThunkAction<any, any, { state: RootState }>;

export default store;
