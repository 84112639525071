import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import { Persistence } from 'firebase/auth';
import { DataSnapshot } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';

export const firebaseConfig = {
  apiKey: process.env.WEB_FIREBASE_API_KEY,
  authDomain: process.env.WEB_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.WEB_FIREBASE_DATABASE_URL,
  projectId: process.env.WEB_FIREBASE_PROJECT_ID,
  storageBucket: process.env.WEB_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.WEB_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.WEB_FIREBASE_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

const messaging = firebase.messaging;
const auth = firebase.auth;
const database = firebase.database;
const analytics = firebase.analytics;

const setPersistence = auth().setPersistence;

export { messaging, auth, database, analytics, setPersistence, Persistence, DataSnapshot };

export default firebase;
