import React, { useMemo } from 'react';
import styled, { css } from 'styled-components/native';
import { StyledViewProps } from 'styled-components/types';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { Icon, FormatDate } from '@common/components';

type MessageFooterProps = {
  id?: string;
  time?: Date;
  read?: boolean;
  showStatus?: boolean;
} & StyledViewProps;

const FooterWrap = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`;

const BubbleDate = styled(FormatDate).attrs({ type: 'Time' })`
  color: ${rgba(colors.grey2, 0.6)};
  font-family: ${fonts.regular};
  font-size: 10px;
`;

type ReadStatusProps = {
  read: boolean;
};

const ReadStatus = styled.View<ReadStatusProps>(
  ({ read }: { read: boolean }) => css`
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background: ${read ? colors.primary : colors.grey7};
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  `
);

const MessageFooter = ({ id, time, read = true, showStatus = false, ...props }: MessageFooterProps) => {
  const statusColor = useMemo(() => {
    if (read) {
      return colors.white;
    }

    return rgba(colors.grey4, 0.35);
  }, [read]);

  if (!time && !showStatus) {
    return null;
  }

  return (
    <FooterWrap {...props}>
      {time && <BubbleDate testID={`${id}-time-footer`} value={time} />}

      {showStatus && (
        <ReadStatus read={read}>
          <Icon color={statusColor} size={10} name="done" />
        </ReadStatus>
      )}
    </FooterWrap>
  );
};

export default MessageFooter;
