import React, { useContext, useMemo } from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';
import { StyledViewProps } from 'styled-components/types';

import { colors } from '@common/theme';
import { Icon } from '@common/components';

import MainButton from './MainButton';
import { ToolbarContext } from '@common/components/ChatScreen/ActionToolbar/ToolbarContext';

type ButtonAreaProps = {
  animatedSendVisibility: Animated.AnimatedValue;
  disableMainButton?: boolean;
  disableSecondaryButton?: boolean;
  isInputPopulated: boolean;
  onButtonPress: () => void;
  onMicPress: () => void;
  onSendPress: () => void;
  showSecondaryMic: boolean;
  showSend: boolean;
} & StyledViewProps;

const ToolbarRight = styled.View`
  align-items: center;
  margin: 6px 6px 6px 0;
`;

const SendButtonWrap = styled.View`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  box-shadow: 0 2px 6px rgba(110, 110, 110, 0.2);
  top: -50px;
`;

const SendButton = styled.TouchableOpacity.attrs({ activeOpacity: 0.8 })`
  opacity: ${({ disabled }: { disabled: boolean }) => (!disabled ? 1 : 0.5)};
`;

const ButtonArea = ({
  animatedSendVisibility,
  disableMainButton,
  disableSecondaryButton,
  isInputPopulated,
  onButtonPress,
  onMicPress,
  onSendPress,
  showSecondaryMic,
  showSend,
  ...props
}: ButtonAreaProps) => {
  const { variant, isListening, isTranscribing } = useContext(ToolbarContext);

  const sendButtonStyles = useMemo(
    () => ({
      opacity: animatedSendVisibility,
      transform: [
        {
          translateY: animatedSendVisibility.interpolate({
            inputRange: [0, 1],
            outputRange: [20, 0]
          })
        }
      ]
    }),
    [animatedSendVisibility]
  );

  const mainButtonProps = useMemo(
    () => ({
      disabled: disableMainButton || isTranscribing,
      isInputPopulated,
      isMicActive: isListening,
      onPress: onButtonPress,
      variant
    }),
    [disableMainButton, isInputPopulated, isListening, onButtonPress, variant, isTranscribing]
  );

  const disableSecondBtn = useMemo(() => disableSecondaryButton || isTranscribing, [isTranscribing, disableSecondaryButton]);

  return (
    <ToolbarRight {...props}>
      {showSend && (
        <SendButtonWrap as={Animated.View} style={sendButtonStyles}>
          <SendButton onPress={onSendPress} disabled={disableSecondBtn}>
            <Icon color={colors.primary} size={22} name="send" />
          </SendButton>
        </SendButtonWrap>
      )}
      {showSecondaryMic && (
        <SendButtonWrap>
          <SendButton onPress={onMicPress}>
            <Icon color={colors.primary} size={22} name="mic" />
          </SendButton>
        </SendButtonWrap>
      )}
      <MainButton {...mainButtonProps} />
    </ToolbarRight>
  );
};

export default ButtonArea;
