import React, { FC, useMemo } from 'react';
import { Platform, Switch, TextInput, TextInputProps } from 'react-native';
import { unstable_createElement as createElement } from 'react-native-web';
import { Field as FinalField, useForm } from 'react-final-form';
import styled, { css } from 'styled-components/native';
import { StyledTextInputProps } from 'styled-components/types';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Picker, Icon } from '@common/components';
import { colors, fonts } from '@common/theme';
import { useCustomReplaceValue, useDependantFieldUpdater, useFieldOptions, useSelector } from '@common/hooks';
import { identity, isWeb, pathOr, displayPickerDate, toISOString, getDateString, omit } from '@common/utils';
import { getTimeFormat } from '@common/store/reducers/user';

import RichText from './RichText';

type BaseFieldProps = {
  dependantAllowedValues?: DependantAllowedValues;
  field: FieldType;
  format?: (value: string) => string;
  parse?: (value: string) => string;
  display?: (value: string) => string | boolean;
  callback?: () => void;
  isLoading?: boolean;
  as?: any;
  type?: string;
  disabled: boolean;
  intent: string | undefined;
} & TextInputProps;

const ListItemOuter = styled.View`
  margin-bottom: 30px;
`;

const ListItemTitle = styled.Text`
  color: ${colors.grey4};
  font-size: 11px;
  font-family: ${fonts.medium};
  text-align: left;
  margin-bottom: 10px;
`;

const MandatoryMarker = styled(ListItemTitle)`
  font-size: 8px;
  color: ${colors.red};
`;

type ListItemValueProps = {
  as?: FC;
};

const ListItemValue = styled.Text<ListItemValueProps>(
  ({ as }: { as: FC }) =>
    (!as || !as.displayName || !['Switch', 'Picker'].includes(as.displayName)) &&
    css`
      font-family: ${fonts.regular};
      font-size: ${!isWeb ? 12 : 16}px;
      color: ${colors.grey4};
    `
);

type ListItemDividerProps = {
  active?: boolean;
};

const ListItemDivider = styled.View<ListItemDividerProps>(
  ({ active }: { active: boolean }) => css`
    width: 100%;
    height: 1px;
    margin-top: 5px;
    background: ${active ? colors.primary : 'rgba(112, 112, 112, 0.2)'};
  `
);

const webInputCssReset = {
  borderWidth: 0,
  padding: 0
};

const BaseField = ({ field, format = identity, parse = identity, display = identity, callback, ...props }: BaseFieldProps) => (
  <FinalField
    name={field.name}
    format={format}
    parse={parse}
    render={({ input, meta }) => {
      const isTextField = ['Currency', 'Text', 'LongTextArea', 'Phone', 'Email', 'Number'].includes(field.type);
      const isCheckboxField = field.type === 'Checkbox';
      const isSelectField = ['Picklist', 'QuickReply'].includes(field.type);
      const isDateField = ['Date', 'DateTime'].includes(field.type);
      const isMultiPicklistField = field.type === 'MultiPicklist';
      const isLookupField = field.type === 'Lookup' || field.type === 'MultiLookup';

      const textFieldProps = {
        children: null,
        onChangeText: input.onChange,
        value: display(input.value)
      };

      const checkboxfieldWebProps = {
        activeThumbColor: colors.primary,
        activeTrackColor: colors.primaryLight
      };

      const checkboxFieldProps = {
        children: null,
        onValueChange: input.onChange,
        value: display(input.value),
        ...(isWeb ? checkboxfieldWebProps : {})
      };

      const mobileSelectFieldProps = {
        children: input.value ? display(input.value) : '-- Select an option --',
        style: !input.value ? { color: colors.grey8 } : {}
      };

      const webSelectFieldProps = {
        children: null,
        selectedValue: input.value || field.value || '',
        onValueChange: input.onChange
      };

      const webDateFieldProps = {
        children: null,
        onChange: input.onChange,
        value: display(input.value)
      };

      const onPressProp = {
        onPress: input.onFocus
      };

      const lookupFieldProps = {
        children: null,
        title: display(input.value || field.value || ''),
        onPress: callback,
        onValueChange: input.onChange
      };

      const fieldProps = {
        children: display(input.value),
        // props needed for TextField (TextInput)
        ...(isTextField ? textFieldProps : {}),
        // props needed for CheckboxField (Switch)
        ...(isCheckboxField ? checkboxFieldProps : {}),
        // [native] props needed for PicklistField (Picker)
        ...(!isWeb && isSelectField ? mobileSelectFieldProps : {}),
        // [web] props needed for PicklistField (Picker)
        ...(isWeb && isSelectField ? webSelectFieldProps : {}),
        // props needed for MultiPicklistField
        ...(isMultiPicklistField ? onPressProp : {}),
        // [web] props needed for DateField
        ...(isWeb && isDateField ? webDateFieldProps : {}),
        // [native] props needed for non text/checkbox fields
        ...(!isWeb && !(isTextField || isCheckboxField || isLookupField) ? onPressProp : {}),
        // [web] props needed for Lookup
        ...(isLookupField ? lookupFieldProps : {}),
        ...props
      };

      return (
        <ListItemOuter>
          <ListItemTitle>
            {field.name.toUpperCase()}
            {field.ismandatory && <MandatoryMarker>*</MandatoryMarker>}
          </ListItemTitle>

          <ListItemValue onFocus={input.onFocus} onBlur={input.onBlur} {...fieldProps} />

          <ListItemDivider active={!isCheckboxField && meta.active} />
        </ListItemOuter>
      );
    }}
  />
);

const textFieldStyles = Platform.select<StyledTextInputProps>({
  default: {},
  web: { outlineWidth: 0 }
});

const TextField = (props: BaseFieldProps) => <BaseField as={TextInput} underlineColorAndroid="transparent" style={textFieldStyles} {...props} />;

const CurrencyField = (props: BaseFieldProps) => (
  <BaseField
    as={TextInput}
    keyboardType="numeric"
    underlineColorAndroid="transparent"
    style={textFieldStyles}
    display={(value: string) => value?.toString()}
    {...props}
  />
);

const WebDateField = (p: any) => createElement('input', p);
WebDateField.displayName = 'WebDateInput';

const DateField = (props: BaseFieldProps) => {
  const { field } = props;
  const timeFormat = useSelector(getTimeFormat);

  const isDateTime = field.type === 'DateTime';

  if (!isWeb) {
    return <BaseField {...props} format={(value: string) => getDateString(value, timeFormat, field.type as FieldDateTypes)} />;
  }

  const webDateFieldProps = omit(['dependantAllowedValues'], props);

  return (
    <BaseField
      as={WebDateField}
      {...webDateFieldProps}
      type={isDateTime ? 'datetime-local' : 'date'}
      style={webInputCssReset}
      display={(value: string) => value && displayPickerDate(value, isDateTime)}
      format={(value: string) => value && toISOString(value)}
      parse={(value: string) => value && toISOString(value)}
    />
  );
};

const WebPicklistField = (props: BaseFieldProps) => {
  const { field, dependantAllowedValues } = props;
  const form = useForm();

  const { fieldOptions, fieldReactKey } = useFieldOptions(field, dependantAllowedValues);
  const replaceValue = useCustomReplaceValue();

  const fieldState = form.getFieldState(field.name);
  const fieldValue = fieldState?.value ?? '';
  const hasValue = !!fieldValue;

  const styleProp = useMemo(
    () => ({
      ...webInputCssReset,
      ...(!hasValue ? { color: colors.grey8 } : {})
    }),
    [hasValue]
  );

  const webPicklistFieldProps = omit(['dependantAllowedValues'], props);

  return (
    <BaseField {...webPicklistFieldProps} key={fieldReactKey} as={Picker} style={styleProp}>
      <option disabled key="rollio_empty" value="">
        -- Select an option --
      </option>
      {fieldOptions.map(v => (
        <option key={v} value={v}>
          {replaceValue(v)}
        </option>
      ))}
    </BaseField>
  );
};

const PicklistField = (props: BaseFieldProps) => {
  const { field, dependantAllowedValues } = props;
  useDependantFieldUpdater(field, dependantAllowedValues);
  const replaceValue = useCustomReplaceValue();

  if (!isWeb) {
    return <BaseField display={(value: string) => replaceValue(value)} {...props} />;
  }

  return <WebPicklistField {...props} />;
};

const display = (value: string) => {
  const count = value?.length ?? 0;
  return `${count} item${count !== 1 ? 's' : ''}`;
};

const dsiplayCheckbox = (value: any) => {
  let isChecked = false;
  if (value === 'true' || value === 'True') {
    isChecked = true;
  } else if (typeof value === 'boolean') {
    isChecked = value;
  }
  return isChecked;
};

const MultiPicklistField = (props: BaseFieldProps) => <BaseField display={display} onBlur={() => {}} {...props} />;
const CheckboxField = (props: BaseFieldProps) => <BaseField as={Switch} display={dsiplayCheckbox} {...props} />;
const LookupField = (props: BaseFieldProps) => {
  const { field, isLoading, intent } = props;
  const { value } = field;

  if (!field?.lookupObjects?.length || (field?.api?.toUpperCase() === 'ID' && intent === 'Create')) {
    return null; // NOT EDITABLE TODO: show them anyway as ready only
  }

  const IconWrapper = styled.View`
    align-items: center;
    position: absolute;
    right: 0px;
  `;

  const LookupName = styled.Text`
    color: black;
    min-height: 15px;
  `;

  const Spinner = styled.ActivityIndicator.attrs({ color: colors.primary })`
    margin-left: 5px;
  `;

  return (
    <BaseField
      as={TouchableOpacity}
      style={{
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: 0,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start'
      }}
      {...props}
    >
      <LookupName>{value}</LookupName>
      <IconWrapper>{isLoading ? <Spinner /> : <Icon name="search" size={20} color="#000" />}</IconWrapper>
    </BaseField>
  );
};

const RichField = ({ field, format = identity, parse = identity }: BaseFieldProps) => {
  return (
    <FinalField
      name={field.name}
      format={format}
      parse={parse}
      render={({ input, meta }) => (
        <ListItemOuter>
          <ListItemTitle>
            {field.name.toUpperCase()} {field.ismandatory && <MandatoryMarker>*</MandatoryMarker>}
          </ListItemTitle>
          {field && <RichText value={field.value} onChange={input.onChange} />}
          <ListItemDivider active={meta.active} />
        </ListItemOuter>
      )}
    />
  );
};

const Field = (props: BaseFieldProps) => {
  const type = pathOr<FieldTypes | null>(null, ['field', 'type'], props);

  switch (type) {
    case 'Text':
    case 'Phone':
    case 'Email':
      return <TextField {...props} />;

    case 'RichTextArea':
      return <RichField {...props} />;

    case 'LongTextArea':
      return <TextField multiline numberOfLines={3} {...props} />;

    case 'Number':
    case 'Currency':
      return <CurrencyField {...props} />;

    case 'Date':
    case 'DateTime':
      return <DateField {...props} />;

    case 'Picklist':
    case 'QuickReply':
      return <PicklistField {...props} />;

    case 'MultiPicklist':
      return <MultiPicklistField {...props} />;

    case 'Checkbox':
      return <CheckboxField {...props} />;

    case 'MultiLookup':
    case 'Lookup':
      return <LookupField {...props} />;

    default:
      return null;
  }
};

export default Field;
