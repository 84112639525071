import React, { useCallback, useEffect, useState } from 'react';
import { Linking, View } from 'react-native';
import { WebView } from 'react-native-webview';
import styled from 'styled-components/native';

import { iphoneXHomeBarHeight, isWeb} from '@common/utils';
import { colors } from '@common/theme';
import { HeaderBack, LoadingOverlay, ScreenWrap } from '@common/components';
import { RootStackScreenProps } from '@common/navigation';
import { WEBVIEW_SCREEN } from '@common/navigation/routes';
import { useTabBarHeight } from '@common/hooks';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

type WebViewScreenProps = RootStackScreenProps<typeof WEBVIEW_SCREEN>;

const WebViewScreen = ({ route }: WebViewScreenProps) => {
  const externalBrowserInstance = route.params?.openInExternalBrowser;
  const backPress = route.params?.backPress;
  const navigation = useNavigation();
  const header = route.params?.header;
  // const isLoggedIn = useSelector(selectIsLoggedIn);
  const url = route.params?.url;
  const padTabs = route.params?.padTabs ?? true;
  const { tabBarHeight } = useTabBarHeight();
  const openInExternalBrowser = !isWeb && !!externalBrowserInstance;

  const checkExternalBrowser = async () => {
    if (openInExternalBrowser) {
      await Linking.openURL(url);
    }
  };

  useEffect(() => {
    checkExternalBrowser();
  }, [openInExternalBrowser]);

  const onBackPress = () => {
    if (backPress && typeof backPress === 'function') {
      backPress();
    } else {
      navigation.goBack();
    }
  };

  const Container = styled.View`
    flex-direction: column;
    background: ${colors.primary};
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex: 1;
    padding-top: 30px;
    margin-bottom: -50px;
  `;

  const Title = styled.Text`
    color: ${colors.white};
    font-size: 18px;
    font-weight: bold;
  `;

  const Message = styled.Text`
    margin-top: 10px;
    color: ${colors.white};
    font-size: 12px;
  `;

  const ButtonMessage = styled.Text`
    color: #180b7e;
    font-weight: 500;
    font-size: 12px;
  `;

  const ButtonWrap = styled.TouchableOpacity.attrs({ activeOpacity: 0.75 })`
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    background: #e0e0e0;
    height: 30px;
    min-width: 80px;
    padding: 0 12px;
    border-radius: 3px;
    border-width: 1px;
  `;

  const PopUpView = () => (
    <Container>
      <Title>External salesforce login opened</Title>
      <Message>Click "Open with Rollio" on pop-up after login</Message>
      <ButtonWrap onPress={() => Linking.openURL(url)}>
        <ButtonMessage>Retry</ButtonMessage>
      </ButtonWrap>
    </Container>
  );

  const wrapStyles = {
    paddingBottom: padTabs ? tabBarHeight : iphoneXHomeBarHeight
  };

  return (
    <ScreenWrap style={wrapStyles}>
      <HeaderBack onBackPress={onBackPress} title={header} />
      {!openInExternalBrowser ? <WebView cacheMode="LOAD_NO_CACHE" cacheEnabled={false} source={{ uri: url }} incognito /> : <PopUpView />}
    </ScreenWrap>
  );
};

export default WebViewScreen;
