import React from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { Icon } from '@common/components';

type CheckboxProps = {
  checked?: boolean;
  children: string;
  onPress?: () => void;
};

const CheckboxWrap = styled.TouchableOpacity.attrs({ activeOpacity: 0.8 })`
  flex-direction: row;
  opacity: ${({ disabled }: { disabled: boolean }) => (!disabled ? 1 : 0.6)};
`;

const CheckboxLabel = styled.Text`
  margin-left: 3px;
  color: ${colors.grey2};
  font-size: 13px;
  font-family: ${fonts.regular};
`;

const Checkbox = ({ checked = false, children, onPress = () => {} }: CheckboxProps) => (
  <CheckboxWrap onPress={onPress}>
    <Icon name={checked ? 'check-box' : 'check-box-outline-blank'} size={16} color={colors.primary} />
    <CheckboxLabel>{children}</CheckboxLabel>
  </CheckboxWrap>
);

export default Checkbox;
