import { createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';

import { RootState, commonContext } from '@common/store';

export { default as useCustomReplaceValue } from './useCustomReplaceValue';
export { default as useDependantFieldUpdater } from './useDependantFieldUpdater';
export { default as useFieldOptions } from './useFieldOptions';
export { default as useFirebaseListener } from './useFirebaseListener';
export { default as useGetParentDependency } from './useGetParentDependency';
export { default as useHeaderButtons } from './useHeaderButtons';
export { default as useTabBarHeight } from './useTabBarHeight';
export { default as usePanelHeight } from './usePanelHeight';
export { default as useKeyboard } from './useKeyboard';
export { default as useStt } from './useStt';
export { default as useSearchLookup } from './useSearchLookup';
export { default as useIsFirstRender } from './useIsFirstRender';
export { default as useBiometrics } from './useBiometrics';

export const useStore = createStoreHook(commonContext);
export const useDispatch = createDispatchHook<RootState, any>(commonContext);
export const useSelector = createSelectorHook<RootState>(commonContext);
