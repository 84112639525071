import React from 'react';
import styled, { css } from 'styled-components/native';

import { colors } from '@common/theme';
import { Icon } from '@common/components';

import ActionIcon from './ActionIcon';
import SegmentDivider from './SegmentDivider';
import SegmentText from './SegmentText';

type ActiveSegmentProps = {
  label: string;
  last?: boolean;
  onRemovePress?: (icon: { value: string; label: string }, index: number) => void;
  onSelectPress?: (icon: { value: string; label: string }, index: number) => void;
  value: string;
  showRemoveIcon?: boolean;
  showSelectArrow?: boolean;
  index: number;
};

type AdditionSegmentProps = {
  label?: string;
  onAddPress?: () => void;
};

export type PillSegmentProps = ActiveSegmentProps &
  AdditionSegmentProps & {
    showAddIcon?: boolean;
  };

const SegmentOuter = styled.View`
  flex-direction: row;
`;

type SegmentContentProps = {
  padRight?: boolean;
};

const SegmentContent = styled.View<SegmentContentProps>(
  ({ padRight }: { padRight: boolean }) => css`
    padding-left: 5px;
    height: 34px;
    justify-content: center;
    padding-right: ${padRight ? '15px' : '0'};
  `
);

const SegmentContentTouchable = styled.TouchableOpacity.attrs({
  activeOpacity: 0.65
})``;

const ValueLine = styled.View`
  flex-direction: row;
  align-items: center;
`;

const ActiveSegment = ({
  label,
  last,
  onSelectPress = () => {},
  onRemovePress = () => {},
  value,
  showRemoveIcon = false,
  showSelectArrow = false,
  index
}: ActiveSegmentProps) => {
  const touchableSegmentProps = {
    as: SegmentContentTouchable,
    onPress: () => onSelectPress({ value, label }, index)
  };

  const segmentContentProps = !showSelectArrow ? {} : touchableSegmentProps;

  return (
    <SegmentOuter>
      <SegmentContent padRight={!showRemoveIcon} {...segmentContentProps}>
        <SegmentText variant="label">{label}</SegmentText>
        {value && (
          <ValueLine>
            <SegmentText variant="value">{value}</SegmentText>
            {showSelectArrow && <Icon name="keyboard-arrow-down" color={colors.primary} size={16} style={{ marginLeft: 2 }} />}
          </ValueLine>
        )}
      </SegmentContent>
      {!last && <SegmentDivider />}
      {showRemoveIcon && <ActionIcon onPress={() => onRemovePress({ value, label }, index)} />}
    </SegmentOuter>
  );
};

const AdditionOuterWrap = styled.TouchableOpacity`
  flex-direction: row;
`;

const AdditionSegment = ({ label, onAddPress = () => {} }: AdditionSegmentProps) => (
  <SegmentOuter>
    <AdditionOuterWrap activeOpacity={0.65} onPress={onAddPress}>
      <SegmentContent>
        <SegmentText variant="label">{label}</SegmentText>
      </SegmentContent>
      <ActionIcon type="add" />
    </AdditionOuterWrap>
  </SegmentOuter>
);

const PillSegment = ({
  label,
  last = false,
  onAddPress,
  onRemovePress,
  onSelectPress,
  value,
  showAddIcon = false,
  showRemoveIcon,
  showSelectArrow,
  index
}: PillSegmentProps) => {
  if (!showAddIcon) {
    return (
      <ActiveSegment
        label={label}
        last={last}
        onRemovePress={onRemovePress}
        onSelectPress={onSelectPress}
        value={value}
        showRemoveIcon={showRemoveIcon}
        showSelectArrow={showSelectArrow}
        index={index}
      />
    );
  }

  return <AdditionSegment label={label} onAddPress={onAddPress} />;
};

export default PillSegment;
