import { webStorageKeys } from '@common/utils';

const Keychain = {};
const BIOMETRY_TYPE = {};

export const getGenericPassword = () => {};
export const setGenericPassword = () => {};
export const resetGenericPassword = () => {};
export const saveLoginToKeychain = (save: boolean, username: string) => {
  if (save) {
    localStorage.setItem(webStorageKeys.loginEmail, username);
  } else {
    localStorage.removeItem(webStorageKeys.loginEmail);
  }
};

export { Keychain, BIOMETRY_TYPE };
