import React from 'react';
import styled, { css } from 'styled-components/native';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { toDataTestIdFriendly, formatDateValue } from '@common/utils';
import { TouchableWithEvent } from '@common/components';
import { useSelector } from '@common/hooks';
import { getTimeFormat } from '@common/store/reducers/user';

type TableDataRowProps = {
  id: string;
  onPress: () => void;
  selected?: boolean;
  values: Array<string>;
};

type RowWrapProps = {
  selected: boolean;
};

const RowWrap = styled.View<RowWrapProps>(
  ({ selected }: { selected: boolean }) => css`
    padding: 0 5px;
    background-color: ${!selected ? colors.white : rgba(colors.primaryLight, 0.4)};
  `
);

const RowTextWrap = styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-color: ${colors.grey};
  border-bottom-width: 1px;
  padding: 4px 0;
  min-height: 40px;
`;

type RowTextLabelProps = {
  selected: boolean;
};

const RowTextLabel = styled.Text<RowTextLabelProps>(
  ({ selected }: { selected: boolean }) => css`
    flex: 1;
    text-align: center;
    font-family: ${fonts.regular};
    font-size: 12px;
    color: ${colors[!selected ? 'grey4' : 'primary']};
    padding: 0 4px;
  `
);

const TableDataRow = ({ id, onPress, selected = false, values = [] }: TableDataRowProps) => {
  const timeFormat = useSelector(getTimeFormat);

  return (
    <RowWrap selected={selected}>
      <TouchableWithEvent onPress={onPress} activeOpacity={0.7} trackEventId="chat-searchtableitem-click">
        <RowTextWrap>
          {values.map((value, i) => (
            <RowTextLabel testID={`table-data-row-${toDataTestIdFriendly(value)}`} key={`${id}-${i}`} selected={selected}>
              {formatDateValue(value, timeFormat)}
            </RowTextLabel>
          ))}
        </RowTextWrap>
      </TouchableWithEvent>
    </RowWrap>
  );
};

export default TableDataRow;
