import React, { useEffect, useState } from 'react';
import { Animated, TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';
import { darken } from 'polished';

import { colors, fonts } from '@common/theme';
import { boolToNum, trackEvent, toDataTestIdFriendly } from '@common/utils';
import { useCustomReplaceValue } from '@common/hooks';

type PicklistButtonProps = {
  children: string;
  isSelected?: boolean;
  onPress?: () => void;
};

const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);

const selectedBorderColor = darken(0.1, colors.primaryLight);

type ButtonWrapProps = { isSelected: boolean };

const ButtonWrap = styled.TouchableOpacity<ButtonWrapProps>(
  ({ isSelected }: { isSelected: boolean }) => css`
    padding: 0 6px;
    border: solid 1px;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    margin: 3px;
    min-width: 72px;
    min-height: 32px;
    max-width: 100%;
    background: ${isSelected ? colors.primaryLight : colors.grey6};
    border-color: ${isSelected ? selectedBorderColor : colors.grey7};
  `
);

type ButtonLabelProps = { isSelected: boolean };

const ButtonLabel = styled.Text<ButtonLabelProps>(
  ({ isSelected }: { isSelected: boolean }) => css`
    flex: 1 0 auto;
    font-family: ${fonts.regular};
    font-size: 15px;
    text-align: center;
    max-width: 100%;
    color: ${isSelected ? colors.primary : colors.grey4};
  `
);

const PicklistButton = ({ children, isSelected = false, onPress }: PicklistButtonProps) => {
  const [animatedSelected] = useState(new Animated.Value(boolToNum(isSelected)));
  const replaceValue = useCustomReplaceValue();

  const wrapStyles = {
    shadowColor: colors.primary,
    shadowOpacity: animatedSelected.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 0.4]
    }),
    shadowOffset: {
      width: 0,
      height: animatedSelected.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 2]
      })
    },
    shadowRadius: 4
  };

  useEffect(() => {
    Animated.timing(animatedSelected, {
      toValue: boolToNum(isSelected),
      duration: 50,
      useNativeDriver: false
    }).start();
  }, [animatedSelected, isSelected]);

  const onButtonPress = () => {
    if (onPress) {
      trackEvent('chat-picklistbutton-click');
      onPress();
    }
  };

  const value = replaceValue(children);
  return (
    <ButtonWrap
      as={AnimatedTouchableOpacity}
      activeOpacity={0.8}
      isSelected={isSelected}
      onPress={onButtonPress}
      style={wrapStyles}
      testID={`picklist-button-${toDataTestIdFriendly(value)}`}
    >
      <ButtonLabel isSelected={isSelected}>{value}</ButtonLabel>
    </ButtonWrap>
  );
};

export default PicklistButton;
