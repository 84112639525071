import React, { ReactNode } from 'react';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors } from '@common/theme';
import { useDispatch } from '@common/hooks';
import { uiSetLastBotMessageHeight } from '@common/store/reducers/ui';

import BotAvatar from './BotAvatar';
import MessageFooter from './MessageFooter';
import BubbleMessage from './BubbleMessage';

type BubbleProps = {
  isBot?: boolean;
  message: string | ReactNode;
  read?: boolean;
  showAvatar?: boolean;
  showStatus?: boolean;
  textColor?: string;
  time?: Date;
};

type BubbleRowProps = { isBot: boolean };

const BubbleRow = styled.View<BubbleRowProps>(
  ({ isBot }: { isBot: boolean }) => css`
    margin-top: 12px;
    flex-direction: row;
    justify-content: ${!isBot ? 'flex-end' : 'flex-start'};
    align-items: flex-start;
    max-width: 100%;
  `
);

type BubbleWrapProps = { isBot: boolean };

const BubbleOuter = styled.View<BubbleWrapProps>(
  ({ isBot }: { isBot: boolean }) => css`
    max-width: 86%;
    ${!isBot
      ? css`
          align-items: flex-end;
        `
      : css`
          align-items: flex-start;
        `}
  `
);

const BubbleWrap = styled.View<BubbleWrapProps>(
  ({ isBot }: { isBot: boolean }) => css`
    padding: 9px 20px 9px 18px;
    ${!isBot
      ? css`
          background: ${colors.primary};
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 4px;
          border-top-left-radius: 24px;
          border-top-right-radius: 20px;
        `
      : css`
          background: ${colors.grey};
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 24px;
          border-top-left-radius: 4px;
          border-top-right-radius: 24px;
        `}
  `
);

const FooterContainer = styled.View<BubbleWrapProps>(({ isBot }: { isBot: boolean }) =>
  isBot
    ? css`
        margin-left: 10px;
      `
    : undefined
);

const Bubble = ({ isBot = false, message, textColor, time, read, showStatus, showAvatar }: BubbleProps) => {
  const dispatch = useDispatch();

  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: { height }
        }
      }) => showAvatar && dispatch(uiSetLastBotMessageHeight(height))}
    >
      <BubbleRow isBot={isBot}>
        <BubbleOuter isBot={isBot}>
          <BubbleWrap isBot={isBot}>
            <BubbleMessage isBot={isBot} color={textColor}>
              {message}
            </BubbleMessage>
          </BubbleWrap>

          <FooterContainer isBot={isBot}>
            <MessageFooter read={read} showStatus={showStatus} time={time} />
          </FooterContainer>

          {isBot && typeof message === 'string' && showAvatar && <BotAvatar isVisible={showAvatar} />}
        </BubbleOuter>
      </BubbleRow>
    </View>
  );
};

export default Bubble;
