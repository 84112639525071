/* eslint-disable max-len */
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const IconAndroidFace: React.FC<{ size: number; color: string }> = ({ size = 20, color }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 187 188" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.251 169.492c41.663 0 75.437-33.774 75.437-75.437s-33.774-75.437-75.436-75.437c-41.663 0-75.437 33.775-75.437 75.437 0 41.663 33.774 75.437 75.436 75.437zm0 6.158c45.064 0 81.596-36.531 81.596-81.595S138.315 12.46 93.251 12.46c-45.063 0-81.595 36.532-81.595 81.595 0 45.064 36.532 81.595 81.595 81.595z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.1 137.275a3.386 3.386 0 01-1.645 4.498c-7.84 3.641-15.609 4.31-21.974 1.388-6.455-2.963-10.664-9.247-12.047-17.773l6.687-1.084c1.117 6.888 4.272 10.904 8.186 12.701 4.006 1.838 9.613 1.727 16.295-1.375a3.386 3.386 0 014.498 1.645zM97.87 103.292V89.437h6.158v20.014H87.093v-6.159H97.87z"
      fill={color}
    />
    <Path
      d="M136.358 74.041a6.158 6.158 0 11-12.316 0 6.158 6.158 0 0112.316 0zM62.461 74.041a6.158 6.158 0 11-12.316 0 6.158 6.158 0 0112.316 0z"
      fill={color}
    />
  </Svg>
);

export default IconAndroidFace;
