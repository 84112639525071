import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUser } from '@common/store/reducers/user';
import { useDispatch, useSelector } from '@common/hooks';

import { isWeb, nativeAlert } from '@common/utils';
import { Keychain, BIOMETRY_TYPE } from '@common/utils/keychain';
import { getIsBiometricsEnabled, toggleBiometricSetting } from '@common/store/reducers/auth';
import { IconFingerprint, IconFace, IconFaceId, IconTouchId } from '../../assets/Biometrics';

const config = isWeb
  ? {}
  : {
      accessible: Keychain.ACCESSIBLE.WHEN_UNLOCKED,
      authenticationType: Keychain.AUTHENTICATION_TYPE.BIOMETRICS,
      accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_CURRENT_SET,
      authenticationPrompt: {
        title: 'Authentication needed',
        cancel: 'Cancel'
      }
    };

const useBiometrics = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [type, setBiometryType] = useState<BIOMETRY_TYPE | null>(null);
  const biometricsEnabled = useSelector(getIsBiometricsEnabled) && !isWeb && !!type;

  useEffect(() => {
    if (!isWeb) {
      (async () => setBiometryType(await Keychain.getSupportedBiometryType()))();
    }
  }, []);

  const info = useMemo(() => {
    let icon = IconFingerprint;
    let label = 'Biometrics';

    if (isWeb) {
      return { label };
    }

    if (type === Keychain.BIOMETRY_TYPE.TOUCH_ID) {
      label = 'TouchID';
      icon = IconTouchId;
    } else if (type === Keychain.BIOMETRY_TYPE.FACE_ID) {
      label = 'Face ID';
      icon = IconFaceId;
    } else if (type === Keychain.BIOMETRY_TYPE.FACE) {
      label = 'Face';
      icon = IconFace;
    } else if (type === Keychain.BIOMETRY_TYPE.FINGERPRINT) {
      label = 'Fingerprint';
      icon = IconFingerprint;
    }

    return { label, icon };
  }, [type]);

  const resetBiometrics = useCallback(
    async (callback?: () => void) => {
      try {
        const result = await Keychain.resetGenericPassword(config);

        if (result) {
          dispatch(toggleBiometricSetting(false));
          callback?.();
        }
      } catch (error) {
        nativeAlert({
          message: 'There was an error',
          onOkPress: () => {},
          title: 'Settings Error'
        });
      }
    },
    [dispatch]
  );

  const toggleBiometrics = useCallback(
    async (enable: boolean) => {
      try {
        const credentialsExist = await Keychain.getGenericPassword(config);

        if (enable) {
          if (credentialsExist) {
            await resetBiometrics();
          }

          const credentials = await Keychain.setGenericPassword('rollio', user?.email, config);

          if (credentials) {
            dispatch(toggleBiometricSetting(true));
            nativeAlert({
              message: `You will login as ${user?.email}`,
              onOkPress: () => {},
              title: `${info.label} Enabled`
            });
          }
        } else {
          resetBiometrics(() => dispatch(toggleBiometricSetting(false)));
        }
      } catch (error: any) {
        nativeAlert({
          message: error.message,
          onOkPress: () => {},
          title: 'Settings Error'
        });
      }
    },
    [dispatch, resetBiometrics, info.label, user?.email]
  );

  const authenticate = useCallback(
    async (onSuccess: (email: string, rememberMe: boolean, isBiometric: boolean) => void) => {
      try {
        const credentials = await Keychain.getGenericPassword(config);

        if (credentials) {
          onSuccess(credentials.password, false, true);
        } else {
          nativeAlert({
            message: 'There was an error authenticating',
            onOkPress: () => {},
            title: 'Biometric Error'
          });
        }
      } catch (error) {
        console.error('No credentials found or canceled by user');
      }
    },
    [dispatch]
  );

  return {
    type,
    info,
    toggleBiometrics,
    resetBiometrics,
    biometricsEnabled,
    authenticate
  };
};

export default useBiometrics;
